import React from 'react'
import styled from "styled-components";
import { mobile } from '../responsive';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;
const Img = styled.img`
  width: 50%;
  aspect-ratio: 16/9;
  border-radius: 5px;
  background-color: white;
  ${mobile({width: "90%"})}
`

const HomeHeader = () => {
  return (
    <Container>
      <Img src={require("../assets/mansour-pic-169.png")} />
    </Container>
  )
}

export default HomeHeader