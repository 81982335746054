import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import axios from "axios"
import { BASE_URL } from "../constants"
import CircularProgress from "@mui/material/CircularProgress";
import HomePost from './HomePost';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  padding: 1rem;
  margin: 2rem 0;
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`
const PostsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap-reverse;
`

const HomePosts = ({ title, mode }) => {

  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/post?type=${mode}`)
      .then((res) => setPosts(res.data))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [mode]);

  return (
    <Container>
      <Title>{title}</Title>
      <PostsWrapper>
        {loading ? <CircularProgress />
          : posts.map((post, i) => (
          <HomePost key={i} post={post} />
        ))}
      </PostsWrapper>
    </Container>
  )
}

export default HomePosts