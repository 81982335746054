import React from 'react'
import styled from "styled-components"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { mobile } from '../responsive';
import { primary_color } from "../constants"
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${primary_color};
`;

const Section = styled.div`
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  ${mobile({fontSize: "1rem"})}
`

const NavComponent = () => {
  return (
    <Container>
      <Link style={{textDecoration: "none"}} to="/">
        <Section>عائلة منصور الصغير</Section>
      </Link>
      <Link to="/about">
        <Section>
          <HelpOutlineIcon />
        </Section>
      </Link>
    </Container>
  );
}

export default NavComponent