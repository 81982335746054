import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  background-color: white;
`;

const Img = styled.img`
  width: 10rem;
  aspect-ratio: auto;
  cursor: pointer;
  margin: 0 1rem;
  ${mobile({width: "7rem"})}
`

const FooterComponent = () => {

  const redirectToStore = (url) => {
    window.location = url
  }

  return (
    <Container>
      <Img src={require("../assets/ios.png")} />
      <Img onClick={() => {redirectToStore(
        "https://play.google.com/store/apps/details?id=com.abodjo.mansoursoghayer"
      );}} src={require("../assets/google.png")} />
    </Container>
  );
};

export default FooterComponent;