import React from 'react'
import styled from 'styled-components'
import moment from "moment"
import {shimmer} from "../responsive"

const Container = styled.div`
  width: 25rem;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: .5rem;
`;
const Img = styled.img`
  width: 90%;
  aspect-ratio: 16/9;
  border-radius: 5px;
  ${shimmer()}
`
const Title = styled.div`
  width: 100%;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
`
const Text = styled.div`
  width: 100%;
  padding: 1rem;
`

const HomePost = ({ post }) => {
  return (
    <Container>
      <Text>{moment(post.createdAt).format("D/M/YYYY  h:mm a")}</Text>
      <Img src={post.img} />
      <Title>{post.title}</Title>
      <Text>{post.desc}</Text>
    </Container>
  );
}

export default HomePost