import React from 'react'
import FooterComponent from '../components/FooterComponent'
import HomeHeader from '../components/HomeHeader'
import HomePosts from '../components/HomePosts'
import NavComponent from '../components/NavComponent'

const HomePage = () => {
  document.title = "عائلة منصور الصغير | الرئيسية";
  return (
    <>
      <NavComponent />
      <HomeHeader />
      <HomePosts title="الاخبار" mode="news" />
      <HomePosts title="المناسبات" mode="occasion" />
      <FooterComponent />
    </>
  )
}

export default HomePage