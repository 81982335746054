import React from 'react'
import styled from 'styled-components';
import FooterComponent from '../components/FooterComponent';
import HomeHeader from '../components/HomeHeader';
import NavComponent from '../components/NavComponent';

const Text = styled.div`
  padding: 1rem;
  line-height: 1.5rem;
  font-size: 1.25rem;
  margin: 1rem 0;
`

const AboutPage = () => {
  document.title = "عائلة منصور الصغير | من نحن";
  return (
    <>
      <NavComponent />
      <HomeHeader />
      <Text>
        ترجع أسرة الصغير أمراء الخبراء لآل عفالق من العياف من آل جمل من الجحادر
        من قحطان ، ونزحت قبيلة العفالق من جنوب الجزيرة العربية قرابة عام
        التسعمائة الهجرية ، وسكنت البويطن بعنيزة وعمل أبناءها بالزراعة والتجارة
        وعلى أثر خلاف بينهم وبين حكام عنيزة في ذلك الوقت ، عقد آل سليم حكام
        عنيزة إتفاقية مع العفالق أن تثمن لهم مزارعهم وأراضيهم ويخرجون من عنيزة ،
        درأً للحروب والمشاكل ، وخرج آل عفالق في حينها من عنيزة ، وافترقوا قسمين
        ، قسم نزح إلى الأحساء شرق الجزيرة العربية وقسم ذهبوا لمكان يسمى خبراء (
        وهي مكان تجمع المياه ) واستوطنوا فيها وعمروها ، وصارت تسمى ب ( خبراء
        العفالق ) وعينوا عليهم أميراً منهم اسمه منصور بن علي ، وكان قصير القامة
        شجاعاً مقداماً ، وكانوا يلقبون هذا الشخص ( بالصغير ) ،، وصار أهل الخبراء
        يعتزون ويفخرون بأميرهم وأُطلق على أهل الخبراء لقب ( أولاد منصور ) عزوة
        بأميرهم جد أسرة الصغير.
      </Text>
      <FooterComponent />
    </>
  );
}

export default AboutPage