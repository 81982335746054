import { css } from "styled-components";

export const mobile = (props) => {
  return css`
    @media screen and (max-width: 450px) {
      ${props}
    }
  `;
};

export const shimmer = () => {
  return css`
    @keyframes shimmer {
      from {
        background-position: 100% 100%;
      }
      to {
        background-position: 0 0;
      }
    }

    background-image: linear-gradient(
      90deg,
      #eeeeee 0%,
      #eeeeee 40%,
      #dddddd 50%,
      #dddddd 55%,
      #eeeeee 65%,
      #eeeeee 100%
    );
    background-size: 400%;
    animation: shimmer 1500ms infinite;
  `;
};